import { Route } from '@angular/router';

import { ResetRouteLevel, routeLevelsResolver, uiContainerResolver } from '@yuno/yuno/core';

import {
	ResetGuard,
	ResetItemGuard,
	categoriesGuard,
	categoryResolver,
	itemResolver
} from './utils';

const itemRoute: Route = {
	canDeactivate: [ResetItemGuard],
	resolve: {
		item: itemResolver,
		level: routeLevelsResolver
	},
	loadComponent: () => import('./feature/item/item.component').then(c => c.ItemComponent)
};

export const YunoParticipateRoutes: Route[] = [
	{
		path: '',
		canActivate: [categoriesGuard],
		children: [
			{
				path: '',
				loadComponent: () =>
					import('./feature/categories/categories.component').then(
						c => c.CategoriesComponent
					)
			},
			{
				path: ':category',
				canDeactivate: [ResetGuard, ResetRouteLevel],
				resolve: {
					category: categoryResolver
				},
				children: [
					{
						path: '',
						loadComponent: () =>
							import('./feature/category/category.component').then(
								c => c.CategoryComponent
							),
						children: [
							{
								path: 'map',
								resolve: [uiContainerResolver, routeLevelsResolver],
								data: { containerPosition: 'absolute', routeLevels: 2 },
								loadComponent: () =>
									import('./feature/category/map-view/map.component').then(
										c => c.MapViewComponent
									)
							},
							{
								path: 'list',
								resolve: [uiContainerResolver, routeLevelsResolver],
								data: { routeLevels: 2 },
								loadComponent: () =>
									import('./feature/category/list-view/list.component').then(
										c => c.ListViewComponent
									)
							},
							{
								path: '',
								redirectTo: 'list',
								pathMatch: 'full'
							}
						]
					},
					{
						path: 'map/:item',
						...itemRoute
					},
					{
						path: 'list/:item',
						...itemRoute
					},
					{
						path: '',
						redirectTo: 'list',
						pathMatch: 'full'
					}
				]
			}
		]
	}
];
