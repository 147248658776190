import { OverlayModule } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { RouterLink } from '@angular/router';

import { FloatingListComponent } from '@yuno/yuno/features/ui';

@Component({
	selector: 'yuno-feature-user-dropdown',
	imports: [OverlayModule, RouterLink, FloatingListComponent],
	template: `
		<button
			type="button"
			(click)="toggle()"
			cdkOverlayOrigin
			#trigger="cdkOverlayOrigin"
			class="flex h-8 w-8 items-center justify-center rounded-full bg-white text-yuno-gray-500 hover:opacity-65">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				fill="currentColor"
				class="h-5 w-5">
				<path
					fill-rule="evenodd"
					d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
					clip-rule="evenodd" />
			</svg>
		</button>

		<!-- Dropdown menu -->
		<ng-template
			cdkConnectedOverlay
			[cdkConnectedOverlayOrigin]="trigger"
			[cdkConnectedOverlayOpen]="$isOpen()"
			[cdkConnectedOverlayHasBackdrop]="true"
			[cdkConnectedOverlayBackdropClass]="'opacity-0'"
			(backdropClick)="close()">
			<yuno-ui-floating-list-container>
				<li>
					<a
						(click)="close()"
						[routerLink]="['/']"
						class="block w-full p-4 hover:bg-yuno-gray-600">
						Dashboard
					</a>
				</li>
				<li>
					<a
						[routerLink]="['/', 'auth', 'logout']"
						class="block w-full p-4 hover:bg-yuno-gray-600">
						Sign out
					</a>
				</li>
			</yuno-ui-floating-list-container>
		</ng-template>
	`,
	styles: ``,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDropdownComponent {
	$isOpen = signal<boolean>(false);

	toggle(): void {
		this.$isOpen.set(!this.$isOpen());
	}

	close(): void {
		this.$isOpen.set(false);
	}
}
