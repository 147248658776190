import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	PreloadAllModules,
	provideRouter,
	withComponentInputBinding,
	withPreloading,
	withRouterConfig
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { DefaultOptions } from '@apollo/client';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { provideAuthConfig } from '@yuno/angular-auth';
import { GraphqlAngularModule } from '@yuno/angular-graphql';
import { ApiModule } from '@yuno/angular/api';
import { ENVIRONMENT } from '@yuno/yuno/core';

import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

const defaultApolloOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore'
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all'
	},
	mutate: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all'
	}
};

let providers = [
	// provideExperimentalZonelessChangeDetection(),
	provideRouter(
		appRoutes,
		withComponentInputBinding(),
		withRouterConfig({ paramsInheritanceStrategy: 'always' }),
		withPreloading(PreloadAllModules)
	),

	provideServiceWorker('ngsw-worker.js', {
		enabled: !isDevMode(),
		registrationStrategy: 'registerWhenStable:30000'
	}),
	provideAnimations(),
	provideStore({
		router: routerReducer
	}),
	provideEffects([]),

	provideRouterStore(),
	provideHttpClient(withInterceptorsFromDi()),
	provideHotToastConfig({
		style: {
			border: '1px solid #171c23', // yuno-gray-800
			padding: '0.75rem 1rem',
			color: '#b0b5bc', // yuno-gray-200
			backgroundColor: '#2e3746' // yuno-gray-600
		}
	}),
	provideAuthConfig({ redirect: ['./'], authUrl: environment['yuno-api'] }),
	importProvidersFrom(
		GraphqlAngularModule.withConfig({
			uri: environment['yuno-graphql'],
			defaultOptions: defaultApolloOptions
		}),
		ApiModule.forRoot({
			url: environment['yuno-api'],
			cdn_url: environment['yuno-cdn-api-storage']
		})
	),
	{
		provide: ENVIRONMENT,
		useValue: environment || {}
	}
];

if (isDevMode()) {
	providers = [
		...providers,
		provideStoreDevtools({
			connectInZone: false,
			maxAge: 25, // Retains last 25 states
			logOnly: !isDevMode(), // Restrict extension to log-only mode
			autoPause: true, // Pauses recording actions and state changes when the extension window is not open
			trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
			traceLimit: 75 // maximum stack trace frames to be stored (in case trace option was provided as true)
		})
	];
}

export const appConfig: ApplicationConfig = {
	providers
};
