import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { getState } from '@ngrx/signals';

import { NavbarStyle, NavbarStyleEnum } from '@yuno/api/interface';
import { MainUiService } from '@yuno/yuno/core';
import { AppStore } from '@yuno/yuno/features/functions';
import { UserDropdownComponent } from '@yuno/yuno/features/user-dropdown';

@Component({
    selector: 'yuno-internal',
    templateUrl: './internal.component.html',
    imports: [NgStyle, NgClass, RouterOutlet, RouterLink, UserDropdownComponent],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InternalComponent {
	private readonly meta = inject(Meta);
	private readonly router = inject(Router);
	private readonly ui = inject(MainUiService);
	private readonly appStore = inject(AppStore);

	navbarDisplay = this.ui.display;
	routeLevels = this.ui.routeLevels;
	navbarColors = this.ui.navbarColors;
	navbarType = this.ui.navbarType;

	containerPosition = this.ui.containerPosition;

	constructor() {
		effect(
			() => {
				const state = getState(this.appStore);

				const navType =
					state.data?.config?.interface?.navbarType || NavbarStyleEnum.default;
				const navColors = state.data?.config?.colors?.navbar;

				this.ui.setNavbarType(navType);
				this.ui.setNavbarColors(navColors);

				this.setBrowserThemeColor(navType, navColors?.background);
			},
			{
				allowSignalWrites: true
			}
		);
	}

	setBrowserThemeColor(navbarType: NavbarStyle, color?: string): void {
		if (navbarType !== 'default') {
			this.meta.updateTag({ content: '#fff' }, 'name=theme-color');
			return;
		}

		this.meta.updateTag({ content: color || '#243B53' }, 'name=theme-color');
	}

	navigateToParentRoute(): void {
		this.router.navigateByUrl(this.getParentUrl());
	}

	private getParentUrl(): string {
		const url = this.router.url;
		const levels = this.routeLevels();

		let segments = url.split('/');
		if (segments.length > levels) {
			segments = segments.slice(0, segments.length - levels);
		}

		return segments.join('/');
	}
}
